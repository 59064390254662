import * as styles from "./author-list.module.css";
import React, { Link } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

function AuthorList({ items, title }) {
  return (
    <div className={styles.root}>
      <h2 className={styles.headline}>{title}</h2>
      <ul className={styles.list}>
        {items.map(({ author, _key }) => {
          const authorName = author && author.name;
          // const authorBio = author && author._rawBio[0].children[0].text;
          return (
            <li key={_key} className={styles.listItem}>
              <div>
                <div className={styles.avatar}>
                  {author && author.image && author.image.asset && (
                    <img
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(100)
                        .height(100)
                        .fit("crop")
                        .url()}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div>
                {/* turning this off for now since the bios have the name and title */}
                {/* <div>{authorName || <em>Missing name</em>}</div> */}
                {author._rawBio && <PortableText blocks={author._rawBio} />}
                <div>
                  <a target="_blank" href={`mailto:${author.email}`}>Email {authorName.split(' ')[0]}</a>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AuthorList;
