// extracted by mini-css-extract-plugin
export var root = "blog-post-module--root--c31ef";
export var title = "blog-post-module--title--f5d0a";
export var mainImage = "blog-post-module--mainImage--20c31";
export var grid = "blog-post-module--grid--40e49";
export var mainContent = "blog-post-module--mainContent--78b3b";
export var metaContent = "blog-post-module--metaContent--9ca04";
export var subscribeOptIn = "blog-post-module--subscribeOptIn--f3922";
export var publishedAt = "blog-post-module--publishedAt--fe080 typography-module--small--2877f";
export var categories = "blog-post-module--categories--007e3";
export var categoriesHeadline = "blog-post-module--categoriesHeadline--69656 typography-module--base--16985";
export var featuredHeadline = "blog-post-module--featuredHeadline--f1056";