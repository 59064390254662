import * as styles from "./share-buttons.module.css";
import React from 'react';
import { Facebook, Twitter, Linkedin, Mail } from 'react-feather';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

const ShareButtons = ({ url, title, description }) => (
  <div className={styles.shareButtons}>
    <FacebookShareButton url={url} quote={description}>
      <Facebook size={30} strokeWidth={1.5} />
    </FacebookShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin size={30} strokeWidth={1.5} />
    </LinkedinShareButton>

    <TwitterShareButton url={url} title={description}>
      <Twitter size={30} strokeWidth={1.5} />
    </TwitterShareButton>
    <EmailShareButton url={url} title={description}>
      <Mail size={30} strokeWidth={1.5} />
    </EmailShareButton>
  </div>
);

export default ShareButtons;