import * as styles from "./blog-post.module.css";
import { differenceInDays, formatDistance, format } from "date-fns";
import AuthorList from "./author-list";
import Container from "./container";
import PortableText from "./portableText";
import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { Link } from "gatsby";
import ShareButtons from './share-buttons';
import PDFEmailForm from "./email-forms/pdf-email-form";
import BlogPostPreviewGrid from './blog-post-preview-grid';

function BlogPost(props) {
  const {
    _rawBody,
    _rawExcerpt,
    authors,
    categories,
    tag,
    title,
    mainImage,
    publishedAt,
    hasPDFForm,
    relatedPosts,
  } = props;
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const relatedShortList = relatedPosts.length > 3 ? relatedPosts.slice(0, 3) : relatedPosts;

  return (
    <article className={styles.root}>
      <Container>
        {mainImage && mainImage.asset && (
          <div className={styles.mainImage}>
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(1200)
                .height(Math.floor((9 / 16) * 1200))
                .fit("crop")
                .auto("format")
                .url()}
              alt={mainImage.alt}
            />
          </div>
        )}
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
            {_rawExcerpt && <ShareButtons url={url} title={title} description={_rawExcerpt[0].children[0].text} />}
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          {/* I think the best way to do this is to:

          1. create a react form
          2. have user fill it out
          3. onSubmit send email to mailchimp API
          4. ideally get a 200 or something back to redirect user
          5. open link to PDf in new tab

          Honorable mention: see if there is a library for this */}
          {hasPDFForm && hasPDFForm.asset.url && <PDFEmailForm pdf={hasPDFForm}></PDFEmailForm>}
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM dd, yyyy")}
              </div>
            )}
            <div className={styles.subscribeOptIn}>
              <div className={styles.mc_embed_signup_footer}>
                <form action="https://benchconsulting.us19.list-manage.com/subscribe/post?u=9987e252175671e64424db8c2&amp;id=e69ee2caba" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <div id="mc_embed_signup_scroll">
                    <label for="mce-EMAIL">Transforming communities in your inbox.</label>
                    <div hidden="true"><input type="hidden" name="tags" value="6241040"></input></div>
                    <input type="email" name="EMAIL" id="mce-EMAIL" class="email" placeholder="email address" required></input>
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <div class="mc-hidden" aria-hidden="true">
                      <input type="text" name="b_9987e252175671e64424db8c2_e69ee2caba" tabindex="-1" value=""></input>
                    </div>
                    {/* <div class="clear btn-container"> */}
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button btn-container"></input>
                    {/* </div> */}
                  </div>
                </form>
              </div>
            </div>
            {authors && <AuthorList items={authors} title="Authors" />}
            {tag && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Tags</h3>
                <ul>
                  {tag.map((tag) => (
                    <Link
                      to="/explore/"
                      state={{ catId: tag.id }}
                    >
                      <li key={tag._id}>{tag.title}</li>
                    </Link>
                  ))}
                </ul>
              </div>
            )}
            {relatedShortList && (
              <span>
                <h2 className={styles.featuredHeadline}>Featured Articles</h2>
                <BlogPostPreviewGrid nodes={relatedShortList} />
              </span>
            )}
            {/* {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map((category) => (
                    <Link
                      to="/explore/"
                      state={{ tagOrCatId: category._id }}
                    >
                      <li key={category._id}>{category.title}</li>
                    </Link>
                  ))}
                </ul>
              </div>
            )} */}
          </aside>
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
